// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-converter-index-js": () => import("./../src/pages/converter/index.js" /* webpackChunkName: "component---src-pages-converter-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-installation-js": () => import("./../src/pages/installation.js" /* webpackChunkName: "component---src-pages-installation-js" */),
  "component---src-pages-specification-index-js": () => import("./../src/pages/specification/index.js" /* webpackChunkName: "component---src-pages-specification-index-js" */)
}

